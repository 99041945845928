<template>

  <!-- <div class="base-app-title-class">
    <h3 class="base-text register-text" id="register-text" @click="showRegister()">注册</h3>
    <h3 class="base-text login-text" style="margin-left: 30px;" id="login-text" @click="showLogin()">登录</h3>

    <h3 class="base-text exit-text" @click="exitLogin()" id="exit-text">退出</h3>
    <h3 class="base-text user-text" style="margin-left: 30px;" id="user-text"></h3>
  </div> -->

  <div v-if="loginCheck">
    <el-menu
      default-active="2"
      mode="horizontal"
      background-color="#393D49"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <el-sub-menu index="1">
        <template #title><el-icon><Menu /></el-icon>菜单</template>
        <el-menu-item index="1-1" @click="showAgreement()"><el-icon><Warning /></el-icon>隐私协议</el-menu-item>
        <el-menu-item index="1-2" @click="clearRecord()"><el-icon><Delete /></el-icon>清除缓存</el-menu-item>
        <el-menu-item index="1-3"  @click="exitLogin()"><el-icon><Close /></el-icon>退出</el-menu-item>
      </el-sub-menu>
      <el-menu-item index="2"><el-icon><User /></el-icon>&nbsp;{{username}}</el-menu-item>
    </el-menu>
  </div>

  <div v-if="!loginCheck">
    <el-menu
      mode="horizontal"
      background-color="#393D49"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <el-menu-item index="1" @click="showRegister()"><el-icon><EditPen /></el-icon>&nbsp;注册</el-menu-item>
      <el-menu-item index="2" @click="showLogin()"><el-icon><User /></el-icon>&nbsp;登录</el-menu-item>
    </el-menu>
  </div>

  <div class="base-app-class">

    <el-menu
      :default-openeds="openeds"
      id="el-menu-element"
      style="border-right: 0px;"
      default-active="0"
      class="el-menu-demo"
      mode="vertical">
      <el-menu-item index="0" @click="gotoIndex()"><el-icon><DocumentAdd/></el-icon>采集任务创建</el-menu-item>
      <el-menu-item index="1" @click="gotoRecord()"><el-icon><DocumentCopy /></el-icon>数据采集</el-menu-item>
      <el-menu-item index="8" @click="gotoHandle()"><el-icon><Document /></el-icon>数据预处理</el-menu-item>
      <el-menu-item index="2" @click="gotoLibrary()"><el-icon><Collection /></el-icon>结果素材库</el-menu-item>

      <el-sub-menu index="3">
        <template #title>
          <span><el-icon><DataAnalysis /></el-icon>综合分析创建</span>
        </template>
        <el-menu-item index="3-1" @click="gotoCommentAnalysis()"><el-icon><ChatLineRound /></el-icon>评论分析</el-menu-item>
        <el-menu-item index="3-2" @click="gotoDanmakuAnalysis()"><el-icon><ChatLineSquare /></el-icon>弹幕分析</el-menu-item>
      </el-sub-menu>
      
      <el-menu-item index="4" @click="gotoMyTask()"><el-icon><User /></el-icon>我的综合分析</el-menu-item>

      <el-sub-menu index="9">
        <template #title>
          <span><el-icon><DataAnalysis /></el-icon>弹幕词云分析</span>
        </template>
        <el-menu-item index="9-1" @click="gotoWordCloud"><el-icon><MostlyCloudy /></el-icon>科目维度</el-menu-item>
        <el-menu-item index="9-2" @click="gotoWordCloudItem"><el-icon><MostlyCloudy /></el-icon>合集维度</el-menu-item>
        <el-menu-item index="9-3" @click="gotoWordCloudOne"><el-icon><MostlyCloudy /></el-icon>单视频维度</el-menu-item>
      </el-sub-menu>

      <!-- <el-menu-item index="9" @click="gotoWordCloud()"><el-icon><MostlyCloudy /></el-icon>弹幕词云分析</el-menu-item> -->

      <el-menu-item index="10" @click="gotoSubtitleDanmaku()"><el-icon><ScaleToOriginal /></el-icon>字幕弹幕对齐</el-menu-item>
      <!-- 单个视频维度 -->
      <el-menu-item index="11" @click="gotoVideoAnalysisByDanmaku()"><el-icon><Film /></el-icon>动态弹幕分析</el-menu-item>
      <el-menu-item index="12" @click="gotoChatGptDanmakuAnalysis()"><el-icon><Cpu /></el-icon>AI智能分析</el-menu-item>
      <el-menu-item index="5" @click="gotoMyExportTask()"><el-icon><Download /></el-icon>我的导出任务</el-menu-item>
      <el-menu-item index="6" @click="gotoMyLog()"><el-icon><MessageBox /></el-icon>数据日志</el-menu-item>
      <el-menu-item index="7" @click="gotoWordManage()"><el-icon><Filter /></el-icon>热词管理</el-menu-item>
    </el-menu>
    
    <router-view class="router-class" id="main-element"></router-view>

    <div class='bottom-base-div'>
      <div class="bottom-text-div">
        Copyright © 2021-2023 cuntujun.com 版权所有 
      </div>
      <div class="bottom-text-div">
        沪公网安备 31011002006201号 
      </div>
      
      <div class="bottom-text-div" @click="gotoBeian()">
        沪ICP备2021028632号-1
      </div>
    </div>

    <el-dialog v-model="dialogAgreementVisible" title="隐私协议">
      <div class="agreement-div" v-for="item in agreementData" :key="item">
        <h3>{{item}}</h3>
      </div>
    </el-dialog>

    <el-dialog v-model="dialogRegisterVisible" title="注册">
      <el-form label-width="120px">
        <el-form-item label="用户名">
          <el-input v-model="username" />
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="pwd" type="password"/>
        </el-form-item>
        <el-form-item label="重复密码">
          <el-input v-model="copypwd" type="password"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doRegister()">注册</el-button>
          <el-button @click="registerCancel()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog v-model="dialogLoginVisible" title="登录">
      <el-form label-width="120px">
        <el-form-item label="用户名">
          <el-input v-model="username" />
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="pwd" type="password"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doLogin()">登录</el-button>
          <el-button @click="loginCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>

import { ElMessageBox, ElMessage } from 'element-plus'
import { ref } from 'vue'
import { h } from 'vue'
import axios from 'axios'

export default {
  name: 'App',
  components: {
    
  },
  data () {
    return {
      dialogAgreementVisible: ref(false),
      dialogRegisterVisible: ref(false),
      dialogLoginVisible: ref(false),
      agreementData: [],
      username: '',
      pwd: '',
      copypwd: '',
      openeds: ['3','9'],
      loginCheck: false
    }
  },

  created() {
    this.createAgreementData();
  },

  mounted() {
    //检测登录状态
    this.checkLogin();
  },

  methods: {
    gotoBeian() {
      window.open("https://beian.miit.gov.cn", "_black")
    },
    gotoChatGptDanmakuAnalysis() {
      this.$router.push({
        path: '/chatgpt_danmaku_analysis'
      })
    },
    gotoVideoAnalysisByDanmaku() {
      this.$router.push({
        path: '/trends_danmaku_analysis'
      })
    },
    gotoMyExportTask() {
      this.$router.push({
        path: '/my_export_task'
      })
    },
    gotoMyTask() {
      this.$router.push({
        path: '/my_task'
      })
    },
    gotoMyLog() {
      this.$router.push({
        path: '/my_log'
      })
    },
    gotoCommentAnalysis() {
      localStorage.removeItem("videoInfo");
      this.$router.push({
        path: '/comment_analysis'
      })
    },

    gotoDanmakuAnalysis() {
      localStorage.removeItem("videoInfo");
      this.$router.push({
        path: '/danmaku_analysis'
      })
    },

    gotoWordManage() {
      this.$router.push({
        path: '/word_manage'
      })
    },

    doRegister() {
      let that = this;
      if(this.checkNull(this.username) || this.checkNull(this.pwd)) {
        ElMessage.info('请输入用户名、密码')
        return;
      }
      if(this.pwd != this.copypwd) {
        ElMessage.info('两次密码不一致')
        return;
      }
      const options = {
        method: 'POST',
        url: '/tUser/register',
        data: {
          username: that.username,
          pwd: that.pwd
        }
      };
      axios(options)
      .then(function (response) {
        console.log(response)
        
        if(response.data.code != 200) {
          ElMessage.error(response.data.msg)
          return;
        }
        
        if(response.data.data.code != 200) {
          ElMessage.error(response.data.data.msg)
          return;
        }

        ElMessage.success("注册成功");

      })
      .catch(function (error) {
        console.log(error)
        ElMessage.error('数据处理失败')
      });
    },

    clearRecord() {
      this.username = localStorage.getItem('username');
      this.pwd = localStorage.getItem('pwd');
      localStorage.clear();
      localStorage.setItem('username', this.username);
      localStorage.setItem('pwd', this.pwd);
      this.checkLogin();
      ElMessage.success("清除成功");
    },

    exitLogin() {
      localStorage.clear();
      this.checkLogin();
    },

    doLogin() {
      var that = this;
      if(this.checkNull(this.username) || this.checkNull(this.pwd)) {
        ElMessage.info('请输入用户名、密码')
        return;
      }
      const options = {
        method: 'POST',
        url: '/tUser/login',
        data: {
          username: that.username,
          pwd: that.pwd
        }
      };
      axios(options)
      .then(function (response) {
        console.log(response)

        if(response.data.code != 200) {
          ElMessage.error(response.data.msg)
          return;
        }
        
        if(response.data.data.code != 200) {
          ElMessage.error(response.data.data.msg)
          return;
        }
      
        ElMessage.success("登录成功");
        localStorage.setItem('username', that.username);
        localStorage.setItem('pwd', that.pwd);
        that.dialogLoginVisible = false
        that.checkLogin();
      
      })
      .catch(function (error) {
        console.log(error)
        ElMessage.error('数据处理失败')
      });
    },

    checkNull(data) {
      if(data == undefined || data == null || data.length <= 0) {
        return true;
      }
      return false;
    },

    checkLogin() {
      this.username = localStorage.getItem("username")
      this.pwd = localStorage.getItem("pwd")
      if(this.checkNull(this.username) || this.checkNull(this.pwd)) {
        this.loginCheck = false
      } else {
        this.loginCheck = true
      }
    },

    showAgreement() {
      this.dialogAgreementVisible = true;
    },

    showRegister() {
      this.dialogRegisterVisible = true;
      this.username = ''
      this.pwd = ''
      this.copypwd = ''
    },

    showLogin() {
      this.dialogLoginVisible = true;
      this.username = ''
      this.pwd = ''
      this.copypwd = ''
    },

    registerCancel() {
      this.dialogRegisterVisible = false
      this.username = ''
      this.pwd = ''
    },

    loginCancel() {
      this.dialogLoginVisible = false
    },

    gotoRecord() {
      this.$router.push({
        path: '/record'
      })
    },

    gotoHandle() {
      this.$router.push({
        path: '/handle'
      })
    },

    gotoLibrary() {
      this.$router.push({
        path: '/library'
      })
    },

    gotoIndex() {
      this.$router.push({
        path: '/index'
      })
    },

    gotoWordCloud() {
      this.$router.push({
        path: '/word_cloud'
      })
    },

    gotoWordCloudItem() {
      this.$router.push({
        path: '/word_cloud_item'
      })
    },

    gotoWordCloudOne() {
      this.$router.push({
        path: '/word_cloud_one'
      })
    },

    gotoSubtitleDanmaku() {
      this.$router.push({
        path: '/subtitle_danmaku'
      })
    },

    createAgreementData() {
      var baseTitle = "bilibili视频解析";

      this.recordArr = new Array();
      var info1 = "本网页应用" + baseTitle + "仅供学习研究使用，如有疑问请联系客服进行协商处理。";
      this.recordArr.push(info1);
      
      var info2 = "联系客服：860993707@qq.com";
      this.recordArr.push(info2);

      this.agreementData = this.recordArr;
    }
  }
}
</script>

<style>
body {
  margin: 0px;
  padding: 0px;
}

.customer_el_form_item_label .el-form-item__label{
    color: transparent;
}

.base-text {
  color: #01AAED
}

.agreement-div {
  text-align: left;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* .black-menu-itme {
  flex: auto;
} */

.el-message-box-image-class {
  width: 160px;
  height: 160px;
  margin-top: 20px;
  will-change: transform;
	overflow:hidden;
  border-radius: 10px 10px 10px 10px;
  /* box-shadow: 8px 8px 6px #DDDDDD; */
}

.router-class {
  margin-bottom: 66px;
  flex: 1;
}

.base-app-title-class {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
}

.base-app-class {
  display: flex;
  flex-direction: row;
}


.bottom-text-div {
  color: #a6a6a6;
  font-size: 10px;
}

.bottom-base-div {
  text-align: center;
  width: 60%; 
  position: fixed;
  bottom: 0px;
  margin-left: 20%;
  margin-right: 20%;
}

h3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
