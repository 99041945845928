const routes = [
    {
        path: '',
        redirect: '/index'
    },
    {
        name: 'my_task',
        path: '/my_task',
        component: () => import('@/pages/my_task.vue'),
        meta: {
            title: '我的综合任务',
            requireAuth: true
        }
    },
    {
        name: 'word_manage',
        path: '/word_manage',
        component: () => import('@/pages/word_manage.vue'),
        meta: {
            title: '热词管理',
            requireAuth: true
        }
    },
    {
        name: 'my_log',
        path: '/my_log',
        component: () => import('@/pages/my_log.vue'),
        meta: {
            title: '数据日志',
            requireAuth: true
        }
    },
    {
        name: 'my_export_task',
        path: '/my_export_task',
        component: () => import('@/pages/my_export_task.vue'),
        meta: {
            title: '我的综合分析',
            requireAuth: true
        }
    },
    {
        name: 'index',
        path: '/index',
        component: () => import('@/pages/index.vue'),
        meta: {
            title: '采集任务创建',
            requireAuth: true
        }
    },
    {
        name: 'record',
        path: '/record',
        component: () => import('@/pages/record.vue'),
        meta: {
            title: '数据采集',
            requireAuth: true
        }
    },
    {
        name: 'library',
        path: '/library',
        component: () => import('@/pages/library.vue'),
        meta: {
            title: '结果素材库',
            requireAuth: true
        }
    },
    {
        name: 'detail',
        path: '/detail',
        component: () => import('@/pages/detail.vue'),
        meta: {
            title: '数据详情',
            requireAuth: true
        }
    },
    {
        name: 'handle',
        path: '/handle',
        component: () => import('@/pages/handle.vue'),
        meta: {
            title: '数据预处理',
            requireAuth: true
        }
    },
    {
        name: 'comment_analysis',
        path: '/comment_analysis',
        component: () => import('@/pages/comment_analysis.vue'),
        meta: {
            title: '评论分析创建',
            requireAuth: true
        }
    },
    {
        name: 'danmaku_analysis',
        path: '/danmaku_analysis',
        component: () => import('@/pages/danmaku_analysis.vue'),
        meta: {
            title: '弹幕分析创建',
            requireAuth: true
        }
    },
    {
        name: 'danmaku_analysis_detail',
        path: '/danmaku_analysis_detail',
        component: () => import('@/pages/danmaku_analysis_detail.vue'),
        meta: {
            title: '弹幕分析详情',
            requireAuth: true
        }
    },
    {
        name: 'comment_analysis_detail',
        path: '/comment_analysis_detail',
        component: () => import('@/pages/comment_analysis_detail.vue'),
        meta: {
            title: '评论分析详情',
            requireAuth: true
        }
    },
    {
        name: 'word_cloud_item',
        path: '/word_cloud_item',
        component: () => import('@/pages/word_cloud_item.vue'),
        meta: {
            title: '弹幕词云分析-合集维度',
            requireAuth: true
        }
    },{
        name: 'word_cloud_one',
        path: '/word_cloud_one',
        component: () => import('@/pages/word_cloud_one.vue'),
        meta: {
            title: '弹幕词云分析-单视频维度',
            requireAuth: true
        }
    },
    {
        name: 'word_cloud',
        path: '/word_cloud',
        component: () => import('@/pages/word_cloud.vue'),
        meta: {
            title: '弹幕词云分析-科目维度',
            requireAuth: true
        }
    },
    {
        name: 'subtitle_danmaku',
        path: '/subtitle_danmaku',
        component: () => import('@/pages/subtitle_danmaku.vue'),
        meta: {
            title: '字幕弹幕对齐',
            requireAuth: true
        }
    },
    {
        name: 'trends_danmaku_analysis',
        path: '/trends_danmaku_analysis',
        component: () => import('@/pages/trends_danmaku_analysis.vue'),
        meta: {
            title: '动态弹幕分析',
            requireAuth: true
        }
    },
    {
        name: 'chatgpt_danmaku_analysis',
        path: '/chatgpt_danmaku_analysis',
        component: () => import('@/pages/chatgpt_danmaku_analysis.vue'),
        meta: {
            title: 'chatgpt_danmaku_analysis',
            requireAuth: true
        }
    },
    {
        name: 'test',
        path: '/test',
        component: () => import('@/pages/test.vue'),
        meta: {
            title: 'test',
            requireAuth: true
        }
    }
];
export default routes